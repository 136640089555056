.footer {
    color: map-deep-get($colors, "black", "default");
    position: relative;
    z-index: 15;
    padding-top: 50px;
    flex: 1 1 100%;
    $elm: &;

    @include media-breakpoint-up(lg) {
        padding-top: 5rem;
    }

    &__top {
        margin-bottom: 8rem;
    }

    &__cta {
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        margin-bottom: 2em;
        height: 100%;
        color: map-deep-get($colors, "white", "default");
    }

    &__title {
        margin-top: .8em;
        color: map-deep-get($colors, "white", "default");

        &--form {
            color: map-deep-get($colors, "black", "default");
            margin: 0;
        }
    }

    &__content {
        font-size: 18px;
    }

    &__form {
        padding: 32px;
        border-radius: $border-radius;
        box-shadow: $box-shadow-medium;
        background: map-deep-get($colors, "white", "default");

        /* stylelint-disable */
        // different form styling for footer form
        .form {
            display: flex;
            flex-wrap: wrap;
            margin: 0 #{0 - $grid-gutter-width / 2};

            &__input--text,
            &__input--email,
            &__input--number,
            &__input--textarea {
                padding-top: 10px;

                &:focus,
                &[data-state="filled"] {
                    + .form__label--compact {
                        font-size: 12px;
                        top: 5px;
                    }
                }

                &:-webkit-autofill {
                    + .form__label--compact {
                        font-size: 12px;
                        top: 5px;
                    }
                }
            }

            &__group {
                flex: 0 0 100%;
                padding: 0 #{$grid-gutter-width / 2};
                max-width: 100%;

                &:nth-child(5),
                &:nth-child(6) {
                    @include media-breakpoint-up(md) {
                        flex: 0 0 50%;
                        max-width: 50%;
                    }
                }

                > .form__label {
                    display: none;

                    &--compact {
                        display: block;
                        position: absolute;
                        top: 10px;
                        left: 26px;
                        pointer-events: none;
                        font-weight: 400;
                        transition: all .1s $transition-default-smoothing;
                    }
                }
            }

            &__input--textarea {
                padding-top: 16px;
            }
        }
        /* stylelint-enable */
    }

    &__bottom {
        padding-top: 1rem;
        font-size: rem-calc(14);
        color: map-deep-get($colors, "brand", "secondary");
        background: map-deep-get($colors, "brand", "primary-light");

        @include media-breakpoint-up(md) {
            padding-top: 1rem;
            padding-bottom: 1rem;
        }
    }

    &__logo {
        height: 100%;
        display: flex;
        flex-flow: column;
        justify-content: center;
    }

    &__list {
        margin-top: 3em;
        margin-bottom: 1em;

        @include media-breakpoint-up(md) {
            margin-top: 0;
            margin-bottom: 0;
            height: 100%;
            display: flex;
            flex-flow: column;
            justify-content: center;
        }

        &--horizontal {
            margin-top: 0;

            @include media-breakpoint-up(md) {
                display: inline-block;
                height: auto;
            }

            #{$elm}__list-item {
                @include media-breakpoint-up(md) {
                    display: inline;
                }
            }

            #{$elm}__link {
                border-color: transparent;
                color: map-deep-get($colors, "white", "default");
                font-weight: 400;
            }

            #{$elm}__link:hover {
                text-decoration: underline;
                color: map-deep-get($colors, "white", "default");
            }
        }
    }

    &__list-item {
        display: flex;
        align-items: flex-start;
        margin-top: 1em;
        margin-bottom: 0.5em;
        font-weight: 700;
        -webkit-font-smoothing: antialiased;

        @include media-breakpoint-up(md) {
            margin-top: 0;
            margin-bottom: 0;
            padding-right: 10px;

            &:last-child::after {
                content: none;
            }
        }

        svg {
            width: 1em;
            height: 1em;
            margin-top: .3em;
            margin-right: .5em;
        }

        svg path {
            fill: map-deep-get($colors, "white", "default");
        }
    }

    &__link {
        display: inline-block;
        margin-bottom: .2em;
        height: 1.5em;
        text-decoration: none;
        color: inherit;
        transition: $transition-default;

        &:hover {
            margin-bottom: 0;
            height: 1.4em;
            text-decoration: none;
            color: map-deep-get($colors, "black", "default");
        }
    }

    &__main-brand {
        margin: 2em 0;
        display: flex;
        align-items: center;

        @include media-breakpoint-up(lg) {
            height: 100%;
            margin: 0;
            float: right;
        }
    }

    &__main-brand-label {
        font-weight: 700;

        @include media-breakpoint-up(md) {
            display: inline-block;
            max-width: 100px;
            margin-right: 20px;
        }
    }

    &__extra-container {
        background: map-deep-get($colors, "brand", "primary");
    }

    &__extra {
        background: rgba(0, 0, 0, 0.2);
        color: map-deep-get($colors, "white", "default");
        padding: 0.875rem 0;

        /* stylelint-disable */
        a {
            color: map-deep-get($colors, "white", "default");
        }
        /* stylelint-enable */
    }

    &__copyright {

        @include media-breakpoint-up(md) {
            display: inline-block;
        }
    }

    &__reference {
        font-size: 14px;
        -webkit-font-smoothing: antialiased;
        display: block;
        width: 100%;

        @include media-breakpoint-up(md) {
            display: inline-block;
            width: auto;
            float: right;
        }
    }

    &__webnl {
        font-weight: 700;
        color: map-deep-get($colors, "brand", "primary");
        transition: $transition-default;

        &:hover {
            color: map-deep-get($colors, "white", "default");
            text-decoration: underline;
        }
    }
}
