.home-intro-slider {
    position: relative;
    height: 100%;
    min-height: 20rem;

    &__slider {
        height: 100%;
        position: relative;
    }

    &__slide {
        width: 100%;
        height: 100%;
        border-top-right-radius: 6rem;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        /* stylelint-disable */
        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
        /* stylelint-enable */
    }

    &__buttons {
        position: absolute;
        right: 0;
        bottom: 0;
    }

    &__button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        border: none;
        background: map-deep-get($colors, "brand", "secondary");

        &:hover,
        &:focus {
            outline: none;
            text-decoration: none;
            color: map-deep-get($colors, "white", "default");
            box-shadow: -2px 2px 7px rgba(map-deep-get($colors, "black", "default"), .2);
            background-color: darken(map-deep-get($colors, "brand", "secondary"), 10%);
        }

        svg {
            width: 20px;
            height: 20px;
        }

        svg path {
            fill: map-deep-get($colors, "white", "default");
        }
    }
}