.home-cta {
    &__image {
        width: 100%;
    }

    &__text-container {
        position: relative;
    }

    &__text {
        position: absolute;
        transform: translateY(calc(-100% + 4rem));
        background: map-deep-get($colors, "white", "default");
        padding: 2.5rem;
        color: map-deep-get($colors, "black", "default");

        /* stylelint-disable */
        h2, h3, h4 {
            margin: 0 0 1.25rem;
            color: map-deep-get($colors, "brand", "primary");
        }

        p:last-of-type {
            margin-bottom: 0;
        }
        /* stylelint-enable */

        @include media-breakpoint-down(sm){
            position: static;
            transform: translateY(0);
        }
    }
}